import React from "react";
import Card from "@mui/material/Card";
import ClearIcon from '@mui/icons-material/Clear';
import { stripFormat } from "../utils/utilFuncs";
import { hexToRgb } from "@mui/material";

const ELEVATION = 12;

export const Answers = ({...props}) => {

    return (
        <div style={{
            justifyContent:'center'
        }}>
        {props.answers.map((e: string, idx: number) => <Card key={e} elevation={ELEVATION} sx={{ backgroundColor: e, height: '6vh'}}>
            <div style={{
                // TODO: centre text in Card component
            }}>{stripFormat(hexToRgb(e))}<ClearIcon onClick={() => props.removeGuess(idx)}/></div>
        </Card>)}
        </div>
    );
}