import React, { useState } from "react";
import Card from "@mui/material/Card";
import { hexToRgb, Stack, useTheme } from "@mui/material";
import QuizIcon from '@mui/icons-material/Quiz';
import { stripFormat } from "../utils/utilFuncs";

interface IHelperColour {
    colour: string,
    hex: string,
    op?: string
}

const helperColours: IHelperColour[] = [
    {
        colour: 'Red',
        hex: '#FF0000',
    },
    {
        colour: 'Green',
        hex: '#00FF00',
    },
    {
        colour: 'Blue',
        hex: '#0000FF',
        op: '#FFFFFF',
    },
    {
        colour: 'Black',
        hex: '#000000',
        op: '#FFFFFF',
    },
    {
        colour: 'White',
        hex: '#FFFFFF',
    },
]

const colourCards = helperColours.map(e => (
    <Card key={e.colour} sx={{
        backgroundColor: e.hex,
        color: e.op,
        textAlign: 'center',
        padding: '3px'
    }}>{stripFormat(hexToRgb(e.hex))}</Card>
))

export const HelperColourBar = () => {

    const [displayHelp, setDisplayHelp] = useState<boolean>(false);
    const theme = useTheme();

    // TODO: fix styling so help icon and colours are on the same row
    return (
        <>
            <QuizIcon onClick={() => {setDisplayHelp(!displayHelp)}}
            sx={{
                color: theme.palette.primary.dark,
                padding: '10px'
            }} />
            {displayHelp ? <Stack direction='row' spacing={1} justifyContent="center"
                alignItems="center">
                {colourCards}
            </Stack> : null}
        </>
    )
}