import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import { Answers } from "./Answers";
import { generateHexCode, RGBtoHex } from "../utils/utilFuncs";
import { hexToRgb, useTheme } from "@mui/material/styles";
import { HelperColourBar } from "./HelperColourBar";

const MODES = ['RGB', 'CMYK', 'LAB', 'Grayscale', 'Bitmap']
const MAX_TRIES = 3;

// TODO: state management
// TODO: add typing
export const Guesser = ({ ...props }) => {

    const theme = useTheme();

    // internally store HEX codes
    const [userGuesses, setUserGuesses] = useState<string[]>([]);
    const [formInputs, setFormInputs] = useState<string[]>(['', '', '']);
    const [displayAnswer, setDisplayAnswer] = useState<boolean>(false);
    const [colourMode, setColourMode] = useState<string>('RGB'); // default colour mode is RGB

    // TODO: can I move this elsewhere?
    const formStyles = {
        // input label when focused
        "& label.Mui-focused": {
            color: theme.palette.primary.dark
        },
        // focused color for input with variant='standard'
        "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary.dark,
        },
        // focused color for input with variant='filled'
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: theme.palette.primary.dark
        },
        // focused color for input with variant='outlined'
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.dark
            }
        },
        // focused background colour
        "& .MuiInputBase-input:focus": {
            // backgroundColor: theme.palette.primary.light,
        },
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        justifyContent: 'center'
    }

    const getFields = () => {
        switch (colourMode) {
            case 'RGB':
                return (
                    <Box sx={formStyles}>
                        <TextField
                            key={0}
                            variant='standard'
                            label="R"
                            value={formInputs[0]}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const temp = [...formInputs];
                                temp[0] = event.target.value;
                                setFormInputs(temp);
                            }}
                        />
                        <TextField
                            key={1}
                            variant='standard'
                            label="G"
                            value={formInputs[1]}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const temp = [...formInputs];
                                temp[1] = event.target.value;
                                setFormInputs(temp);
                            }}
                        />
                        <TextField
                            key={2}
                            variant='standard'
                            label="B"
                            value={formInputs[2]}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const temp = [...formInputs];
                                temp[2] = event.target.value;
                                setFormInputs(temp);
                            }}
                        />
                    </Box>
                )
            default:
                return (<>default</>);
        }
    }

    const newRound = () => {
        setDisplayAnswer(false);
        setFormInputs(['', '', '']);
        setUserGuesses([]);
        newColour();
    }

    const newColour = () => {
        switch (colourMode) {
            case 'RGB':
                props.handleColourChange(generateHexCode());
                break;
            case 'CMYK':
            // TODO
        }
    }

    const handleSubmit = () => {
        setUserGuesses([...userGuesses, RGBtoHex(parseInt(formInputs[0]), parseInt(formInputs[1]), parseInt(formInputs[2]))]);
    }

    const handleViewAnswer = () => {
        setDisplayAnswer(true);
    }

    const handleClearGuess = (index: number) => {
        const guessesToDisplay = [...userGuesses];
        guessesToDisplay.splice(index, 1);
        setUserGuesses(guessesToDisplay);
    }
    useEffect(() => {
        // reset when colour mode changes
        newRound();
    }, [colourMode])

    return (
        <div style={{
            backgroundColor: theme.palette.primary.main,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
            justifyContent: 'start'
        }}>
            {/* <ModeSelector modes={MODES} selectMode={handleModeChange} /> */}
            <HelperColourBar />
            <Button variant="contained" onClick={newRound}>New Colour</Button>
            {getFields()}
            <Button variant="contained" type='submit' onClick={handleSubmit}>Submit</Button>
            <Answers answers={userGuesses} removeGuess={handleClearGuess}/>
            <Button variant="contained" onClick={handleViewAnswer}>See Answer</Button>
            {displayAnswer ? hexToRgb(theme.palette.primary.main) : null}
        </div>
    );
}