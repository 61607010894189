export const colorToHex = (color: number) => {
    const hexadecimal = color.toString(16);
    return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
}

export const RGBtoHex = (red: number, green: number, blue: number) => {
    return "#" + colorToHex(red) + colorToHex(green) + colorToHex(blue);
}

export const stripFormat = (rgbStr: string) => {
    // TODO: use RegEx
    return rgbStr.replace('rgb(', '').replace(')', '')
}

export const generateHexCode = () => {
    return '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substring(1, 7);
}

export const generateRGB = () => {
    return Math.floor(Math.random() * 256)
}
