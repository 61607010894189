import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';
import { Guesser } from './components/Guesser';
import { generateHexCode } from './utils/utilFuncs';

function App() {

  const [colourPrompt, setColourPrompt] = useState<string>(generateHexCode());

  const theme = createTheme({
    palette: {
      primary: {
        main: colourPrompt,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Guesser handleColourChange={setColourPrompt} />
      </div>
    </ThemeProvider>
  );
}

export default App;
